import {forwardRef, memo} from 'react'
import {inputBaseClasses} from '@mui/material/InputBase'
import MUITextField, {TextFieldProps as MUITextFieldProps} from '@mui/material/TextField'
import cn from 'classnames'

import {styled} from '@waybridge/wui'

export type TextFieldProps = MUITextFieldProps

const StyledTextField = memo(
    styled(MUITextField)(() => ({
        '&.TextField-standard': {
            '.MuiInputBase-root.Mui-disabled': {
                backgroundColor: 'transparent',
            },
            [`& .${inputBaseClasses.adornedStart}`]: {
                paddingLeft: 0,
            },
            [`& .${inputBaseClasses.adornedEnd}`]: {
                paddingRight: 0,
            },
        },
    })),
)

/**
 * Text fields let users enter and edit text.
 */
// eslint-disable-next-line react/display-name -- FIXME
const TextFieldBase = forwardRef(
    (
        {className, variant = 'outlined', ...props}: TextFieldProps,
        ref: React.ForwardedRef<HTMLInputElement | HTMLDivElement>,
    ) => (
        <StyledTextField
            className={cn('TextField', `TextField-${variant}`, className)}
            ref={ref}
            variant={variant}
            {...props}
        />
    ),
)

export const TextField = memo(TextFieldBase)

export default TextField

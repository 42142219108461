import {FormattedMessage} from 'react-intl'

import {Field} from './types'

export const allTradesColumnTitles = {
    [Field.accountOwner]: <FormattedMessage defaultMessage="Account Owner" />,
    [Field.id]: <FormattedMessage defaultMessage="ID" />,
    [Field.accountName]: <FormattedMessage defaultMessage="Account Name" />,
    [Field.status]: <FormattedMessage defaultMessage="Status" />,
    [Field.material]: <FormattedMessage defaultMessage="Material" />,
    [Field.accountType]: <FormattedMessage defaultMessage="Account type" />,
    [Field.location]: <FormattedMessage defaultMessage="Location" />,
    [Field.period]: <FormattedMessage defaultMessage="Period" />,
    [Field.orderNumber]: <FormattedMessage defaultMessage="Order #" />,
    [Field.currentBalance]: <FormattedMessage defaultMessage="Current Balance" />,
    [Field.inTransitAndDelivered]: <FormattedMessage defaultMessage="In-transit & Delivered" />,
    [Field.received]: <FormattedMessage defaultMessage="Received" />,
    [Field.released]: <FormattedMessage defaultMessage="Released" />,
    [Field.readyForRelease]: <FormattedMessage defaultMessage="Ready to release" />,
    [Field.settings]: <FormattedMessage defaultMessage="Settings" />,
}

import {ReactNode} from 'react'
import {Navigate} from 'react-router'

import {PermissionRoute, usePermissions} from '@/providers/PermissionAndNavProvider'

type PermissionProtectedRouteProps = {
    isPrincipal?: boolean
    permissions?: PermissionRoute[]
    redirectPath?: string
    children: ReactNode
}
const PermissionProtectedRoute = ({
    permissions = [],
    isPrincipal = false,
    redirectPath = '/',
    children,
}: PermissionProtectedRouteProps) => {
    const perms = usePermissions()

    if (isPrincipal && !perms.isPrincipal) {
        return <Navigate replace to={redirectPath} />
    }

    return permissions.some((permission) => !perms.hasAccess(permission)) ? <Navigate to={redirectPath} /> : children
}

export default PermissionProtectedRoute
